<template>
    <div v-html="columnData">
    </div>
</template>

<script>
export default {
    props: {
        columnData: String,
    }
}
</script>

<style scoped>

</style>